.padding-ultra-light {
    padding: 0.625rem;
}

.padding-light {
    padding: 1.25rem;
}

.padding-medium {
    padding: 3.125rem;
}

.padding-heavy {
    padding: 4.69rem;
}
.stdBottomMargin {
    margin: 0 0 1.75rem 0;
}
.stdBottomMarginMobile {
    margin: 0 0 0.625rem 0;
}
.relBottomMargin {
    margin: 0 0 0.625rem 0;
}
.relBottomMarginMobile {
    margin: 0 0 0.625rem 0;
}

.smallBottomMargin {
    margin: 0 0 0.3125rem 0;
}
.stdTopMargin {
    margin: 1.75rem 0 0 0;
}
.stdTopMarginMobile {
    margin: 0.625rem 0 0 0;
}
.relTopMargin {
    margin: 0.625rem 0 0 0;
}
.relTopMarginMobile {
    margin: 0.625rem 0 0 0;
}
